<template>
  <asterix-modal modal-class="asterix-modal-size" title="Report settings" @cancel="onModalClose">
    <template #content>
      <div class="w-full">
        <sun-multi-checkbox
          class="w-full mb-5"
          list-class="flex justify-left"
          element-class="mr-5"
          :values="optionTypesByRol"
          :selected="optionTypesByRol"
          @change="optionTypeChanged"
        />
        <sun-multi-checkbox
          v-if="optionsByTypesAndRol"
          :key="`options${optionsKey}`"
          class="text-xs w-full text-left overflow-auto"
          list-class="text-left grid grid-cols-6 grid-flow-row py-5"
          element-class="pl-3 pr-2 py-1"
          :values="optionsByTypesAndRol"
          :selected="optionsSelected"
          checkbox-all
          @change="onOptionsSelected"
        />
      </div>
    </template>
    <template #footer>
      <div class="flex align-center justify-end">
        <sun-button
          :loading="saving"
          :disabled="saving || buttonDisabled"
          variant="pill"
          class="custom-p-1 text-xs"
          color="orange"
          @click="emitSaveReport"
        >
          Download
        </sun-button>
        <sun-button
          :disabled="saving"
          variant="pill"
          class="custom-p-1 text-xs ml-5"
          color="white"
          @click="onModalClose"
        >
          Cancel
        </sun-button>
      </div>
    </template>
  </asterix-modal>
</template>

<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import CONFIG from '@/components/molecules/modules/ecommerce/analytics/config';
import { deepClone } from '@/utils/deepClone';

export default {
  name: 'ReportLogModal',
  components: {
    AsterixModal,
  },
  props: {
    userRole: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      optionsKey: 0,
      allOptions: deepClone(CONFIG.allReportOptions),
      optionTypes: CONFIG.optionTypes,
      typesSelected: null,
      optionsByTypesAndRol: null,
      saving: false,
    };
  },
  computed: {
    optionTypesByRol() {
      return this.optionTypes.filter(option => option.allowRoles.includes(this.userRole));
    },
    optionsSelected() {
      return this.optionsByTypesAndRol.filter(option => option.checked);
    },
    buttonDisabled() {
      return this.typesSelected.length === 0;
    },
  },
  beforeMount() {
    this.typesSelected = this.optionTypesByRol.map(opt => opt.value);
  },
  mounted() {
    this.setOptionsByTypes();
  },
  methods: {
    onModalClose() {
      this.$emit('close');
    },
    emitSaveReport() {
      this.saving = true;
      this.$emit('download', { cols: this.optionsSelected, type: this.typesSelected });
    },
    onOptionsSelected(options) {
      this.allOptions.forEach(option => {
        option.checked = options.findIndex(opp => opp.value === option.value) >= 0;
      });
    },

    optionTypeChanged(option) {
      this.typesSelected = option.map(opt => opt.value);
      this.setOptionsByTypes();
      this.optionsKey++;
    },
    setOptionsByTypes() {
      this.optionsByTypesAndRol = this.allOptions
        .filter(({ allowRoles }) => allowRoles.includes(this.userRole))
        .filter(({ allowTypes }) => {
          if (allowTypes.length === this.optionTypes.length) return true;
          for (const type of allowTypes) {
            if (this.typesSelected === []) return allowTypes.length === 4;
            if (this.typesSelected.includes(type)) return true;
          }
          return false;
        });
    },
  },
};
</script>

<style scoped>
.modal-min-height {
  min-height: 40vh;
}
</style>
