var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-modal", {
    attrs: { "modal-class": "asterix-modal-size", title: "Report settings" },
    on: { cancel: _vm.onModalClose },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("sun-multi-checkbox", {
                  staticClass: "w-full mb-5",
                  attrs: {
                    "list-class": "flex justify-left",
                    "element-class": "mr-5",
                    values: _vm.optionTypesByRol,
                    selected: _vm.optionTypesByRol,
                  },
                  on: { change: _vm.optionTypeChanged },
                }),
                _vm.optionsByTypesAndRol
                  ? _c("sun-multi-checkbox", {
                      key: `options${_vm.optionsKey}`,
                      staticClass: "text-xs w-full text-left overflow-auto",
                      attrs: {
                        "list-class":
                          "text-left grid grid-cols-6 grid-flow-row py-5",
                        "element-class": "pl-3 pr-2 py-1",
                        values: _vm.optionsByTypesAndRol,
                        selected: _vm.optionsSelected,
                        "checkbox-all": "",
                      },
                      on: { change: _vm.onOptionsSelected },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex align-center justify-end" },
              [
                _c(
                  "sun-button",
                  {
                    staticClass: "custom-p-1 text-xs",
                    attrs: {
                      loading: _vm.saving,
                      disabled: _vm.saving || _vm.buttonDisabled,
                      variant: "pill",
                      color: "orange",
                    },
                    on: { click: _vm.emitSaveReport },
                  },
                  [_vm._v(" Download ")]
                ),
                _c(
                  "sun-button",
                  {
                    staticClass: "custom-p-1 text-xs ml-5",
                    attrs: {
                      disabled: _vm.saving,
                      variant: "pill",
                      color: "white",
                    },
                    on: { click: _vm.onModalClose },
                  },
                  [_vm._v(" Cancel ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }